import BaseModel from "./BaseModel.js";

export default class FishingLicenceModel extends BaseModel {
  fishingLicenceNumber = null;
  targetOtherDescription = null;
  target = null;
  area = null;
  fishingType = null;
  nbGear = null;
  lgrid = null;
  section = null;
  secteur = null;
  quotas = [];
  constructor() {
    super();
    // Object.seal(this);
  }
}

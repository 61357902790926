<template>
  <fieldset :name="$options.name">
    <h4 class="level1">{{ $t("fishingLicence.conditionsTitle") }}</h4>
    <RadioInput
      v-model="form.hasInactivityReport"
      :options="YesNo"
      :label="$t('fishingLicence.hasInactivityReport')"
      :refeshError="refreshError"
      required
      @error="checkError"
    />
  </fieldset>
</template>

<script>
import { mapState } from "vuex";
import RadioInput from "@/components/widgets/RadioInput.vue";

export default {
  name: "FishingLicenceConditions",
  components: {
    RadioInput
  },
  props: {
    checkError: Function,
    refreshError: Number,
    form: Object
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists
    }),
    language() {
      return this.$i18n.locale;
    },
    YesNo() {
      return this.language === "en"
        ? [
            { value: false, text: "No" },
            { value: true, text: "Yes" }
          ]
        : [
            { value: false, text: "Non" },
            { value: true, text: "Oui" }
          ];
    }
  },
  mounted() {
    this.form.hasInactivityReport ??= true;
  }
};
</script>

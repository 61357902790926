var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{attrs:{"name":"EndEffort"}},[_c('h4',{staticClass:"level2"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.effortStep.endEffort"))+" ")]),(_vm.hasEffortSonar)?_c('DateInput',{attrs:{"label":_vm.$t('editTrip.effortStep.sonarEnd'),"required":_vm.hasEffortSonar === _vm.$const.MANDATORY,"passDate":true,"autodatetime":true,"refeshError":_vm.showErrorData,"minDates":[
      {
        value: _vm.effort.sonarStart,
        text: _vm.$t('editTrip.effortStep.beforeSonarStart')
      }
    ]},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort.sonarEnd),callback:function ($$v) {_vm.$set(_vm.effort, "sonarEnd", $$v)},expression:"effort.sonarEnd"}}):_vm._e(),(_vm.hasEffortSonar)?_c('br'):_vm._e(),_c('DateInput',{attrs:{"label":_vm.$t('editTrip.effortStep.end'),"required":true,"passDate":true,"autodatetime":true,"forceErrorMsg":_vm.lastTripDateAndEffort(_vm.effort.end, _vm.index),"refeshError":_vm.showErrorData,"minDates":[
      {
        value: _vm.effort.sonarEnd,
        text: _vm.$t('editTrip.effortStep.beforeSonarEnd')
      },
      {
        value: _vm.effort.sonarStart,
        text: _vm.$t('editTrip.effortStep.beforeSonarStart')
      },
      {
        value: _vm.effort.start,
        text: _vm.$t('editTrip.beforeStartDate')
      },
      {
        value: _vm.openTrip.departure.datetime,
        text: _vm.$t('editTrip.beforeDepartureDate')
      }
    ]},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort.end),callback:function ($$v) {_vm.$set(_vm.effort, "end", $$v)},expression:"effort.end"}}),(_vm.isGeopositionWidgetVisible)?_c('GeopositionWidget',{attrs:{"disablePos":!_vm.hasPosition,"position":_vm.effort.endPosition,"hasNAFO":_vm.hasNafo,"hasGrid":_vm.hasGrid,"hasLobsterGrid":_vm.hasLobsterGrid,"lgrid":_vm.effort.lgrid,"area":_vm.effort.area,"required":true,"minLat":[_vm.area.latMin ?? _vm.geoLimits.minLat],"maxLat":[_vm.area.latMax ?? _vm.geoLimits.maxLat],"minLong":[_vm.area.lonMin ?? _vm.geoLimits.minLon],"maxLong":[_vm.area.lonMax ?? _vm.geoLimits.maxLon],"gridValidationArray":_vm.gridValidationArray,"nafoGridValidationArray":_vm.nafoGridValidationArray,"refeshError":_vm.showErrorData},on:{"error":_vm.addErrorToParent,"binding":value => {
        _vm.effort.endPosition = value;
      }}}):_vm._e(),_c('br'),(_vm.hasPlnUsdDur)?_c('IntegerInput',{attrs:{"label":_vm.$t('editTrip.effortStep.spotterPlaneDuration'),"min":0,"max":9999,"required":_vm.hasPlnUsdDur === this.$const.MANDATORY,"refeshError":_vm.showErrorData},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort.spotterPlaneDuration),callback:function ($$v) {_vm.$set(_vm.effort, "spotterPlaneDuration", $$v)},expression:"effort.spotterPlaneDuration"}}):_vm._e(),(_vm.hasEffortZonesSearch)?_c('GenericInput',{attrs:{"maxlength":50,"label":_vm.$t('editTrip.effortStep.zonesSearch'),"help":_vm.$t('editTrip.effortStep.zonesSearchHelp'),"required":_vm.hasEffortZonesSearch === this.$const.MANDATORY,"refeshError":_vm.showErrorData,"type":"text"},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort.zonesSearch),callback:function ($$v) {_vm.$set(_vm.effort, "zonesSearch", $$v)},expression:"effort.zonesSearch"}}):_vm._e(),(_vm.hasPlnUsdDur || _vm.hasEffortZonesSearch)?_c('br'):_vm._e(),(_vm.hasNumberDaysFish)?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('IntegerInput',{attrs:{"label":_vm.$t('editTrip.effortStep.nbFishingDays'),"required":_vm.hasNumberDaysFish === _vm.$const.MANDATORY,"min":1,"max":999,"refeshError":_vm.showErrorData},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort['fishingDays']),callback:function ($$v) {_vm.$set(_vm.effort, 'fishingDays', $$v)},expression:"effort['fishingDays']"}})],1):_vm._e(),_c('RadioInput',{attrs:{"label":_vm.$t('editTrip.effortStep.hadSAR'),"required":true,"options":_vm.addDescription(_vm.systemsLists.response, [
        {
          description: _vm.$t('editTrip.effortStep.sarMessage'),
          value: _vm.$const.YES
        }
      ]),"refeshError":_vm.showErrorData},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort['confirmationSar']),callback:function ($$v) {_vm.$set(_vm.effort, 'confirmationSar', $$v)},expression:"effort['confirmationSar']"}}),_c('br'),_c('RadioInput',{attrs:{"label":_vm.$t('editTrip.effortStep.hadMammal'),"required":true,"options":_vm.addDescription(_vm.systemsLists.response, [
        {
          description: _vm.$t('editTrip.effortStep.mmiMessage'),
          value: _vm.$const.YES
        }
      ]),"refeshError":_vm.showErrorData},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort['confirmationMmi']),callback:function ($$v) {_vm.$set(_vm.effort, 'confirmationMmi', $$v)},expression:"effort['confirmationMmi']"}}),_c('br'),_c('RadioInput',{attrs:{"label":_vm.$t('editTrip.effortStep.hadGearLoss'),"required":true,"options":_vm.addDescription(_vm.systemsLists.response, [
        {
          description: _vm.$t('editTrip.effortStep.gearMessage'),
          value: _vm.$const.YES
        }
      ]),"refeshError":_vm.showErrorData},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort['confirmationGear']),callback:function ($$v) {_vm.$set(_vm.effort, 'confirmationGear', $$v)},expression:"effort['confirmationGear']"}}),_c('br'),(_vm.hasInactivityReport)?_c('RadioInput',{attrs:{"label":_vm.$t('editTrip.effortStep.hadInactivity'),"required":true,"options":_vm.addDescription(_vm.systemsLists.response, [
        {
          description: _vm.$t('editTrip.effortStep.inactivityMessage'),
          value: _vm.$const.YES
        }
      ]),"refeshError":_vm.showErrorData},on:{"error":_vm.addErrorToParent},model:{value:(_vm.effort['confirmationInactivity']),callback:function ($$v) {_vm.$set(_vm.effort, 'confirmationInactivity', $$v)},expression:"effort['confirmationInactivity']"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <fieldset :name="$options.name">
    <h4 class="level1">{{ $t("units.title") }}</h4>
    <RadioInput
      v-model="form.unitWeight"
      :label="$t('units.weight.label')"
      :options="weightOptions"
      :refeshError="refreshError"
      required
      @error="checkError"
    />
  </fieldset>
</template>

<script>
import RadioInput from "@/components/widgets/RadioInput.vue";

export default {
  name: "UnitsOfMeasurement",
  components: {
    RadioInput
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    refreshError: {
      type: Number,
      default: 0
    },
    checkError: {
      type: Function,
      required: true
    }
  },
  computed: {
    weightOptions() {
      return [
        {
          value: 2,
          text: this.$t("units.weight.pound.text"),
          description: this.$t("units.weight.pound.description")
        },
        {
          value: 1,
          text: this.$t("units.weight.kilo.text"),
          description: this.$t("units.weight.kilo.description")
        },
        {
          value: 0,
          text: this.$t("units.weight.default.text"),
          description: this.$t("units.weight.default.description")
        }
      ];
    }
  }
};
</script>

import BaseModel from "./BaseModel.js";

export default class GeopositionModel extends BaseModel {
  latitude = null;
  longitude = null;
  autolat = null;
  autolon = null;
  nafo = null;
  grid = null;
  lgrid = null;
  constructor() {
    super();
    Object.seal(this);
  }
}
